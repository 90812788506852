import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'
import DropdownField from './Form/DropdownField'
import './NavigationTabs.scss'

const NavigationTabs = () => {
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState('Events')

  const currentPartner = useStoreState((state) => state.partner.selected)
  const isEdoPartner = currentPartner?.value === 'edo'

  const tabs = [
    { text: 'Events', link: '/event', visible: true },
    { text: 'Users', link: '/users', visible: true },
    { text: 'Utility Programs', link: '/utility-programs', visible: isEdoPartner },
    { text: 'Reports', link: '/report', visible: true },
    { hr: true, visible: isEdoPartner },
  ]

  const openADROptions = [
    { value: 'node', label: 'Node Management' },
    { value: 'job', label: 'Job Queue Management' },
  ]

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const handleOpenADRChange = (selectedOption) => {
    switch (selectedOption.value) {
      case 'node':
        navigate('/openadr/node')
        break
      case 'job':
        navigate('/openadr/queue')
        break
    }
  }

  return (
    <div className="wrapper-navigation">
      <div className="navigation-tabs">
        <div className="tabs-container">
          {tabs
            .filter((tab) => tab.visible)
            .map((tab, index) => (
              <React.Fragment key={index}>
                {tab.hr ? (
                  <hr className="tab-separator" />
                ) : (
                  <Link
                    to={tab.link}
                    className={`tab ${activeTab === tab.text ? 'active' : ''}`}
                    onClick={() => handleTabClick(tab.text)}
                  >
                    {tab.text}
                  </Link>
                )}
              </React.Fragment>
            ))}
          {isEdoPartner && (
            <div className="open-adr-dropdown">
              <DropdownField
                name="openADR"
                options={openADROptions}
                onChange={handleOpenADRChange}
                placeholder="Open ADR"
                className="open-adr-selector"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NavigationTabs
